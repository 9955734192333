import { TaskDataUi } from './tasks-worker.model';

export const SIDEBAR_WIDTH = '361px';
export const MIN_NUMBER_OF_CHARACTERS_FOR_SIDEBAR_SEARCH = 3;

export const COLLAPSED_SIDEBAR_POLLING_FACTOR = 2;
export const NON_ACTIVE_TAB_POLLING_FACTOR = 2;

export enum Tabs {
  todo,
  completed,
}

export const propertiesToBeFilteredBySearchString: Array<keyof TaskDataUi> = [
  'patientName',
  'payerName',
  'clientName',
  'accountNumber',
];

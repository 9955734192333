import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { Observable, catchError, tap } from 'rxjs';
import { AppConfig } from './app-config';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  config!: AppConfig;

  readonly configLocation = '/assets/app.config.json';
  readonly errorMessage =
    'Failed to load app.config.json file, does it exist? Is it in the correct format?';

  constructor(
    private handler: HttpBackend,
    private authClientConfig: AuthClientConfig,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  public load(): Observable<AppConfig> {
    const httpClient = new HttpClient(this.handler);
    return httpClient.get<AppConfig>(this.configLocation).pipe(
      tap((config) => {
        this.config = config;
        this.setupAuth0Config();
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  private setupAuth0Config(): void {
    if (!this.document.defaultView) {
      return;
    }
    this.config.auth0Config.authorizationParams = {
      ...this.config.auth0Config.authorizationParams,
      // eslint-disable-next-line camelcase
      redirect_uri: this.document.defaultView.location.origin + '/callback',
    };
    this.authClientConfig.set(this.config.auth0Config);
  }
}

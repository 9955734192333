import {
  LimitedResultOfTaskSummaryDto,
  TaskSummaryDto,
  TasksClient,
} from '@cloudmed/resolution-services-api-client';
import { filter, finalize, map } from 'rxjs';
import { TasksWorkerListBaseState } from '../state/tasks-worker-list-base.state';
import { GetTasksParams } from 'src/app/shared/models/tasks.model';
import { convertToGetTasksParams } from 'src/app/shared/utils/tasks.utils';

export abstract class TasksWorkerListBaseEffect {
  protected abstract getTasksArgs: GetTasksParams;
  constructor(
    protected readonly tasksClient: TasksClient,
    protected readonly state: TasksWorkerListBaseState
  ) {}

  loadTasks() {
    this.state.setLoadingState(true);
    this.tasksClient
      .getTasks(...convertToGetTasksParams(this.getTasksArgs))
      .pipe(
        filter((response: LimitedResultOfTaskSummaryDto) => !!response.items),
        map((response: LimitedResultOfTaskSummaryDto) => response.items!),
        finalize(() => {
          this.state.setLoadingState(false);
        })
      )
      .subscribe((items: TaskSummaryDto[]) => {
        this.state.setDataState(items);
      });
  }
}
